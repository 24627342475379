import { GetStaticProps } from 'next';
import { Colors } from '@kvika/audur-theme';
import { AudurFooter, AudurHeader } from '@kvika/audur-prismic-types';
import Layout from '../components/Layout';
import LinkCard from '../components/LinkCard';
import ImageCard from '../components/ImageCard';
import { GridItem, MainGridContainer } from '../components/grid-container';
import { ColumnClass, Gutters } from '../types/Types';
import { Route } from '../navigation/Routes';
import { TranslatedStrings } from '../data/TranslatedStrings';
import { getAudurFooter, getAudurHeader } from '../utils/Utils';

type Props = {
  footer: AudurFooter;
  header: AudurHeader;
};

const NotFound = ({ footer, header }: Props): JSX.Element => {
  const { Page404 } = TranslatedStrings.is;
  return (
    <Layout footer={footer} header={header}>
      <MainGridContainer backgroundColor={Colors.DarkGray} gutters={Gutters.Both} style={{ minHeight: 600 }}>
        <GridItem className={ColumnClass.Left}>
          <LinkCard
            heading={<h1>{Page404.heading}</h1>}
            subHeading={<h3>{Page404.subHeading}</h3>}
            linkSource={{
              href: Route.Home,
              title: Page404.linkText,
            }}
          />
        </GridItem>
        <GridItem className={ColumnClass.Right}>
          <ImageCard
            imageSource={{
              src: '/svg/lakehouse.svg',
              alt: 'cabin',
              width: 500,
              height: 500,
            }}
          />
        </GridItem>
      </MainGridContainer>
    </Layout>
  );
};

export default NotFound;

export const getStaticProps: GetStaticProps<Props> = async () => {
  const footer = await getAudurFooter();
  const header = await getAudurHeader();

  return {
    props: {
      footer,
      header,
    },
  };
};
